<script lang="ts" setup>
const { t } = useI18n({
  useScope: 'local',
});

const emits = defineEmits(['item-clicked']);

const isAuthenticated = useAuthState();
const baseStore = useBaseStore();
const isDesktop = useIsDesktop();

const countOfUnseenConversations = computed(() => baseStore.state.countOfUnseenConversations);

const moreDropdownState = ref(false);
const onScroll = () => (moreDropdownState.value = false);

onMounted(() => document.addEventListener('scroll', onScroll));
onUnmounted(() => document.removeEventListener('scroll', onScroll));
</script>

<template>
  <TwNavUnauthenticated v-if="!isAuthenticated" @item-clicked="emits('item-clicked')" />
  <nav v-else class="tw-header-nav">
    <ul class="tw-header-nav-list">
      <li class="tw-header-nav-list-item">
        <NuxtLink to="/dashboard/matches" class="tw-header-nav-list-item-link" @click="emits('item-clicked')">
          <Icon name="bi:house-door" class="icon" />
          <span class="label">
            {{ t('dashboard') }}
          </span>
        </NuxtLink>
      </li>

      <li class="tw-header-nav-list-item relative">
        <NuxtLink to="/messaging/dashboard" class="tw-header-nav-list-item-link" @click="emits('item-clicked')">
          <Icon name="bi:chat" class="icon" />
          <span class="label">
            {{ t('messages') }}
          </span>
          <TwCircledNumber v-if="countOfUnseenConversations > 0" :number="countOfUnseenConversations"
            class="position-desktop-circle" />
        </NuxtLink>
      </li>

      <li class="tw-header-nav-list-item">
        <NuxtLink to="/suche" class="tw-header-nav-list-item-link" @click="emits('item-clicked')">
          <Icon name="bi:search" />
          <span class="label">
            {{ t('search') }}
          </span>
        </NuxtLink>
      </li>

      <li class="tw-header-nav-list-item">
        <TwUiMenu v-if="isDesktop" items-aligned="right" class="relative">
          <template #trigger="{ toggle }">
            <TwUiAction :is="'button'" variant="text" class="sub-menu-trigger" @click="toggle"
              aria-label="sub-menu-button">
              {{ t('more') }}
              <Icon name="bi:caret-down-fill" class="icon-dropdown" />
            </TwUiAction>
          </template>

          <template #items>
            <TwLayoutPageNavigationSubmenu @item-clicked="emits('item-clicked')"
              class="tw-header-nav-secondary-desktop" />
          </template>
        </TwUiMenu>
      </li>

      <li v-if="isDesktop">
        <TwUiActivateSubscription />
      </li>
    </ul>
  </nav>
</template>

<style lang="scss">
@import '~/assets/styles/tw-variables.scss';
@import '~/assets/styles/tw-mixins.scss';

.circle {
  width: 20px;
  height: 20px;
  background: $error;

  .circle-text {
    color: $white;
    font-size: 12px;
    font-weight: bold;
  }
}

a.activate-subscription {
  background-color: $success;
  color: $white;
  border: none;
  padding: 0.5rem;
  border-radius: 0.222rem;
  align-items: center;
  width: fit-content;

  &:hover {
    color: $white !important;
  }
}

.icon {
  &-diamond {
    fill: #fff;
    width: 1rem;
  }

  &-dropdown {
    width: 0.5rem;
    height: 0.5rem;
  }
}

.relative {
  position: relative;
}

.position-desktop-circle {
  @include tw-is-desktop {
    position: absolute !important;
    right: -4px;
    top: -4px;
  }
}

.tw-header-nav {
  &-list {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &-secondary {
    &-desktop {
      display: none;
    }

    &-mobile {
      display: block;
    }
  }

  a,
  .tw-header-nav-secondary-desktop a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    font-size: 1em;
    font-weight: normal;
    text-decoration: none;
    text-decoration-color: $primary;
    text-decoration-style: solid;
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-thickness: auto;
    text-size-adjust: 100%;
    white-space: nowrap;

    &:hover {
      color: $primary;
      text-decoration-line: none;
    }
  }

  a.router-link-active {
    text-decoration-line: underline;
  }

  .tw-header-nav-secondary-desktop {
    a {
      gap: 0.5rem;
      padding: 0.5rem 1.5rem;

      &:hover {
        background-color: $grayBackground;
      }
    }
  }

  .tw-header-nav-secondary-mobile .link,
  .tw-header-nav-secondary-desktop .link {
    display: flex;
    flex-direction: row;
  }

  .tw-header-nav-secondary-mobile .link,
  .tw-header-nav-secondary-desktop .link {
    display: flex;
    flex-direction: row;
  }
}

.sub-menu-trigger {
  gap: 0.5rem;
  border: none;
  display: flex;
  font-size: 1rem;
  background: none;
  align-items: center;
  font-weight: normal !important;
  text-decoration: none !important;
  padding: 0.5rem !important;

  &:hover {
    color: $primary;
    cursor: pointer;
  }
}

@include tw-is-desktop {
  .tw-header-nav {
    display: flex;
    justify-content: end;

    &-list {
      align-items: center;
      flex-direction: row;
      justify-content: end;
    }

    &-secondary {
      &-desktop {
        display: block;
      }

      &-mobile {
        display: none;
      }
    }
  }
}
</style>

<i18n lang="json">{
  "de": {
    "dashboard": "Matches",
    "messages": "Nachrichten",
    "search": "Suche",
    "more": "Mehr"
  },
  "en": {
    "dashboard": "Matches",
    "messages": "Messages",
    "search": "Search",
    "more": "More"
  }
}</i18n>
